import React, { useState } from "react";
import { useContents } from "../../Contexts/contentsCtx";
import { useTags } from "../../Contexts/tagsCtx";
import { contentServices } from "../../Services/contentServices";
import { useToken } from "../../Contexts/tokenCtx";
import { useAuth } from "../../Contexts/authCtx";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Utils/pagination";
import Notification from "../../Utils/notification";
import Table from "./table";
import Modal from "./modal";

export default function Content() {
  const { contents, setContents } = useContents();
  const { tags, setTags } = useTags();
  const { token, setToken } = useToken();
  const { auth, setAuth } = useAuth();
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  // MOdal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selected, setSelected] = useState();

  const closeModal = () => setModalIsOpen(false);
  function handleOpenModal(e) {
    setSelected(e);
    setModalIsOpen(true);
  }

  // content edit
  function handleSubmit(e) {
    e.preventDefault();
    const { title, tags, shared } = e.target;
    if (token && auth && selected) {
      contentServices
        .getContentEdit(
          selected?.id,
          token,
          title.value,
          tags.value,
          shared.value,
          auth?.id
        )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.success === true) {
            setNotification(result);
            setModalIsOpen(false);
            contentServices
              .getContentAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result?.success === true) {
                  setContents(result);
                }
              })
              .finally(() => {});
          } else {
            console.error(result);
          }
        });
    }
  }
  // content create
  function handleBtnCreate() {
    navigate("/uploads");
  }
  // content donwload
  async function handleBtnDown(fileName, type) {
    let response;
    if (type === "video") {
      response = await contentServices.getDownLoadVideo(fileName);
    }
    if (type === "poster") {
      response = await contentServices.getDownLoadPoster(fileName);
    }
    if (type === "file") {
      console.log("file");
    }
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setNotification({
        success: true,
        msg: "Амжилттай татагдсан",
      });
      setModalIsOpen(false);
    } else {
      setNotification({
        success: false,
        msg: `Амжилтгүй ${response.statusText} ${response.status}`,
      });
    }
  }
  // content delete
  function handleBtnDlt(id) {
    if (token && selected) {
      contentServices
        .getContentDelete(token, id)
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            setModalIsOpen(false);
            contentServices
              .getContentAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result?.success === true) {
                  setContents(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
          }
        });
    }
  }
  // pagination change
  function onPageChange(page) {
    if (token) {
      contentServices
        .getContentAll(token, page, 8)
        .then((response) => response.json())
        .then((result) => {
          if (result?.success === true) {
            setContents(result);
          }
        })
        .finally(() => {});
    }
  }

  return (
    <div className="container mx-auto">
      <div className="mx-5 mb-10">
        <button
          onClick={handleBtnCreate}
          className="bg-[#0a5491] my-5 rounded-xl px-10 py-2 text-[14px] text-[#fff]"
        >
          Файл нэмэх
        </button>
        <Table contents={contents?.data} handleOpenModal={handleOpenModal} />
        <Pagination
          currentPage={contents?.pagination.currentPage}
          totalPages={Math.ceil(contents?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
        {modalIsOpen && selected ? (
          <Modal isOpen={modalIsOpen} closeModal={closeModal}>
            <p className="mb-10 uppercase text-center">Дэлгэрэнгүй</p>
            <form onSubmit={handleSubmit}>
              <div className="flex mb-2.5 flex items-center">
                <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
                  Гарчиг :
                </label>
                <input
                  defaultValue={selected?.title}
                  required
                  type="text"
                  name="title"
                  placeholder="Гарчиг"
                  className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                />
              </div>
              <div className="flex mb-2.5 flex items-center">
                <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
                  #Tag :
                </label>

                <select
                  required
                  name="tags"
                  placeholder="tags"
                  defaultValue={selected?.tags}
                  className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                >
                  {tags?.data.map((tag, i) => {
                    return (
                      <option key={i} selected value={tag.name}>
                        {tag.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex mb-2.5 flex items-center">
                <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
                  Хуваалцах төрөл :
                </label>
                <select
                  required
                  name="shared"
                  placeholder="shared"
                  defaultValue={selected?.shared}
                  className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                >
                  <option selected value="public">
                    Бүгд нь
                  </option>
                  <option value="only me">Өөртөө</option>
                </select>
              </div>

              <button
                type="submit"
                className="w-full mt-5 uppercase rounded-xl border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Засах
              </button>
            </form>
            <div className="flex justify-between mt-5">
              <button
                onClick={() =>
                  handleBtnDown(selected?.fileName, selected?.type)
                }
                className="w-full  uppercase rounded-xl border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Татах
              </button>
              <button
                onClick={() => handleBtnDlt(selected?.id)}
                className="w-full ml-5 uppercase rounded-xl border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Устгах
              </button>
            </div>
          </Modal>
        ) : (
          ""
        )}
        {notification ? (
          <Notification
            notification={notification}
            setNotification={setNotification}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
