import React, { createContext, useContext, useEffect, useState } from "react";
import { ratesServices } from "../Services/ratesServices";
import { useToken } from "./tokenCtx";
import moment from "moment";

const initialVal = {
  rates: null,
  setRates: () => {},
};

export const RatesContext = createContext(initialVal);

export function useRates() {
  return useContext(RatesContext);
}

export const RatesProvider = (props) => {
  const [rates, setRates] = useState();

  useEffect(() => {
    ratesServices
      .getRatesAll(1, 8)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setRates(result);
        }
      })
      .finally(() => {});
  }, []);

  return (
    <RatesContext.Provider value={{ rates, setRates }}>
      {props.children}
    </RatesContext.Provider>
  );
};
export default RatesProvider;
