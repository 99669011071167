import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  TokenProvider,
  AuthProvider,
  TagsProvider,
  UserProvider,
  ContentsProvider,
  ContVideoProvider,
  ContPosterProvider,
  ContFileProvider,
  HomeProvider,
  RatesProvider,
} from "./Contexts/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TokenProvider>
        <TagsProvider>
          <ContentsProvider>
            <UserProvider>
              <AuthProvider>
                <ContVideoProvider>
                  <ContPosterProvider>
                    <ContFileProvider>
                      <HomeProvider>
                        <RatesProvider>
                          <App />
                        </RatesProvider>
                      </HomeProvider>
                    </ContFileProvider>
                  </ContPosterProvider>
                </ContVideoProvider>
              </AuthProvider>
            </UserProvider>
          </ContentsProvider>
        </TagsProvider>
      </TokenProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
