import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Header, Footer } from "../Components/index";
import { useEffect, useRef, useState } from "react";
import { IoCaretUp } from "react-icons/io5";
import { CiPlay1, CiCircleChevUp, CiFileOn, CiGrid41 } from "react-icons/ci";

export default function Layout() {
  const containerRef = useRef(null);
  let location = useLocation();
  const [scroll, setScroll] = useState({
    scrollY: "",
  });
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setScroll({
          scrollY: window.scrollY,
        });
      }
    };
    handleScroll();
    if (containerRef.current) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [containerRef.current]);

  const scrollToTop = () => {
    if (containerRef.current) {
      window.scrollTo({
        top: containerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      window.scrollTo({
        top: containerRef.current.offsetTop + containerRef.current.offsetHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <body ref={containerRef}>
      <Header />
      <div className="fixed left-0 top-1/2 z-50">
        {location?.pathname !== "/" ? (
          ""
        ) : (
          <div className="relative flex flex-col hidden lg:block">
            <div className="mx-5 my-2 p-2 rounded-full bg-[#fff]">
              <a href="#video">
                <CiPlay1 size={26} />
              </a>
            </div>
            <div className="mx-5 my-2 p-2 rounded-full bg-[#fff]">
              <a href="#poster">
                <CiGrid41 size={26} />
              </a>
            </div>
            <div className="mx-5 my-2 p-2 rounded-full bg-[#fff]">
              <a href="#file">
                <CiFileOn size={26} />
              </a>
            </div>
          </div>
        )}
      </div>
      <div>
        <Outlet />
      </div>
      <Footer />
      {scroll?.scrollY !== 0 ? (
        <button onClick={scrollToTop}>
          <CiCircleChevUp
            size={35}
            className="text-[#000] border border-[#fff] bg-[#fff] rounded-full fixed z-50 bottom-20 right-5"
          />
        </button>
      ) : (
        ""
      )}
    </body>
  );
}
