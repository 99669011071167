const getSearchValue = async (page, limit, value) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/search?page=${page}&limit=${limit}&value=${value}`,
    requestOptions
  );
};

export const searchServices = {
  getSearchValue,
};
