import React, { useEffect } from "react";

export default function Notification({ notification, setNotification }) {
  useEffect(() => {
    if (Object.keys(notification).length > 0) {
      setTimeout(() => {
        setNotification(null);
      }, 2000);
    }
  }, []);
  return (
    <div
      className={`absolute right-3 top-20 border-l-4 p-4 shadow-xl ${
        notification.success === true
          ? "bg-green-100  border-green-500 text-green-700"
          : "bg-red-100  border-red-500 text-red-700"
      }`}
      role="alert"
    >
      <p>{notification.msg}</p>
    </div>
  );
}
