import React, { useState, useRef } from "react";
import { FaCirclePlay } from "react-icons/fa6";
import { HiOutlineDownload } from "react-icons/hi";
import { contentServices } from "../../Services/contentServices";
import { ratesServices } from "../../Services/ratesServices";
import { useRates } from "../../Contexts/ratesCtx";
import Notification from "../../Utils/notification";
import { Flex, Rate } from "antd";
import moment from "moment";
const desc = ["terrible", "bad", "normal", "good", "wonderful"];

export default function Page({ data }) {
  const { rates, setRates } = useRates();
  const [notification, setNotification] = useState(null);
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState({
    id: "",
    state: false,
    isHovered: false,
  });

  const handlePlayClick = (e, id) => {
    e.preventDefault();
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying({
        id: id,
        state: true,
      });
    }
  };
  const handleBtnDown = async (fileName) => {
    let response = await contentServices.getDownLoadVideo(fileName);
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.mp4`;
      document.body.appendChild(a);
      a.click();
      setNotification({
        success: true,
        msg: "Амжилттай татагдсан",
      });
      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      setNotification({
        success: false,
        msg: `Амжилтгүй ${response.statusText} ${response.status}`,
      });
    }
  };

  const [value, setValue] = useState(3);
  function onChangeRate(value) {
    // console.log(desc[value - 1]);
    // console.log(value, data);
    ratesServices
      .getRatesCreate(data?.title, desc[value - 1], value)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success === true) {
          setNotification(result);
          ratesServices
            .getRatesCreate(1, 8)
            .then((response) => response.json())
            .then((result) => {
              if (result?.success === true) {
                setRates(result);
              }
            })
            .finally(() => {});
        } else {
          console.error(result);
        }
      });
  }

  return (
    <div>
      <div>
        {!playing.state && (
          <>
            <div
              className="cursor-pointer relative w-full pb-[56.25%] bg-cover bg-no-repeat"
              onClick={(e) => handlePlayClick(e, data.id)}
              style={{
                backgroundImage: "url(./5.jpg)",
              }}
            >
              <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                <FaCirclePlay
                  // clePlay
                  size={30}
                  className="text-[#fff] z-30 absolute"
                />

                <p className="z-10 uppercase p-7 text-center font-semibold text-[#fff] text-shadow">
                  {data?.title}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <video
        muted
        autoPlay={true}
        playsInline
        ref={videoRef}
        controls
        className={` ${playing?.state ? "block" : "hidden"} w-full`}
      >
        <source
          src={`${process.env.REACT_APP_UPLOADS_URL}/${data?.fileName}`}
          type="video/mp4"
        />
      </video>
      <div className="flex justify-between items-center mt-1 mx-2">
        {/* <p>{moment(data?.createdAt).format("YYYY-MM-DD HH:MM")}</p> */}

        <Rate tooltips={desc} onChange={onChangeRate} value={value} />
        <button onClick={() => handleBtnDown(data?.fileName)}>
          <HiOutlineDownload />
        </button>
      </div>
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </div>
  );
}
