import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const DropdownMenu = ({ user, setAuth }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  function handleLogOut() {
    cookies.remove("token");
    navigate("/");
    window.location.reload();
  }

  return (
    <div className="relative inline-block text-left z-50">
      <div className="flex items-center">
        <button
          className="uppercase block md:hidden  bg-[#0a5491] text-[#fff] px-3 py-1 rounded-full"
          onClick={toggleMenu}
        >
          {user?.firstName.split("")[0]}
        </button>
        <button
          className="uppercase hidden md:block bg-[#0a5491] text-[#fff] px-3 py-1 rounded-full"
          onClick={toggleMenu}
        >
          {user?.firstName}
        </button>
        <svg
          className="-mr-1 ml-2 h-5 w-5 hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      {isMenuOpen && (
        <div
          className="origin-top-right  absolute right-2 mt-6 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          onMouseLeave={closeMenu}
        >
          <div className="py-1">
            <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              <Link to="/about" className="mr-10 text-[14px]">
                Хэрэглэгчийн мэдээлэл
              </Link>
            </div>
            {/* <div
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <Link to="/uploads" className="mr-10 text-[14px]">
                файл хуулах
              </Link>
            </div> */}
            <div
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            >
              <button onClick={handleLogOut} className="mr-10 text-[14px]">
                Гарах
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
