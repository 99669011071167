import React, { useEffect } from "react";
import { useContVideo } from "../../Contexts/contentsCtxVideo";
import { contentServices } from "../../Services/contentServices";
import Pagination from "../../Utils/pagination";
import Card from "../card/card-video";
import Selecting from "../../Utils/selecting";

export default function VideoList() {
  const { contVideo, setContVideo } = useContVideo();

  function onPageChange(page) {
    contentServices
      .getAllContentClient(page, 12, "video")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContVideo({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }

  function contentSelect(select) {
    setContVideo(select);
  }
  function handleBtnReset(type) {
    contentServices
      .getAllContentClient(1, 12, type)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContVideo({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }
  return (
    <div className="container mx-auto">
      <div className="mx-5 my-10">
        <Selecting
          type="video"
          contentSelect={contentSelect}
          handleBtnReset={handleBtnReset}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
          {/* {contVideo?.data.map((e, i) => {
            return <Card data={e} key={i} />;
          })} */}

          {contVideo &&
            contVideo?.data.map((e, i) => {
              return e.shared === "public" ? <Card data={e} key={i} /> : "";
            })}
        </div>
        <Pagination
          currentPage={contVideo?.pagination.currentPage}
          totalPages={Math.ceil(contVideo?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
