import React, { createContext, useContext, useEffect, useState } from "react";
import { useToken } from "./tokenCtx";
import { userServices } from "../Services/userServices";

const initialVal = {
  auth: null,
  setAuth: () => {},
};

export const AuthContext = createContext(initialVal);

export function useAuth() {
  return useContext(AuthContext);
}
export const AuthProvider = (props) => {
  const [auth, setAuth] = useState();
  const { token, setToken } = useToken();
  useEffect(() => {
    if (token) {
      userServices
        .getUserAuth(token)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setAuth(result.user);
          }
        })
        .finally(() => {});
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
