import React, { useState } from "react";
import { Link } from "react-router-dom";

const DropdownMenu = ({ auth }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="relative inline-block text-left z-50">
      <button
        type="button"
        className="flex items-center text-[14px]"
        onClick={toggleMenu}
      >
        Хянах самбар
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {isMenuOpen && (
        <div
          className="origin-top-right left-[1px]  absolute right-0 mt-6 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          onMouseLeave={closeMenu}
        >
          <div className="py-1">
            {auth?.role === "admin" ? (
              <>
                <div className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                  <Link to="/users" className="mr-10 text-[14px]">
                    Хэрэглэгч
                  </Link>
                </div>
                <div
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <Link to="/tags" className="mr-10 text-[14px]">
                    Tags
                  </Link>
                </div>
                <div
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <Link to="/contents" className="mr-10 text-[14px]">
                    Content
                  </Link>
                </div>
                <div
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <Link to="/rates" className="mr-10 text-[14px]">
                    rates
                  </Link>
                </div>
              </>
            ) : auth?.role === "user" ? (
              <div
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                <Link to="/contents" className="mr-10 text-[14px]">
                  Content
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
