import React, { createContext, useContext, useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";
import { useToken } from "./tokenCtx";
import moment from "moment";

const initialVal = {
  contents: null,
  setContents: () => {},
};

export const contentsContext = createContext(initialVal);

export function useContents() {
  return useContext(contentsContext);
}

export const ContentsProvider = (props) => {
  const [contents, setContents] = useState();
  const { token, setToken } = useToken();
  useEffect(() => {
    if (token) {
      contentServices
        .getContentAll(token, 1, 8)
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result?.success === true) {
            setContents(result);
          }
        })
        .finally(() => {});
    }
  }, [token]);

  return (
    <contentsContext.Provider value={{ contents, setContents }}>
      {props.children}
    </contentsContext.Provider>
  );
};
export default ContentsProvider;
