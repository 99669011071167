import React, { createContext, useContext, useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";

const initialVal = {
  data: null,
  setData: () => {},
};

export const contClientContext = createContext(initialVal);

export function useData() {
  return useContext(contClientContext);
}

export const HomeProvider = (props) => {
  const [data, setData] = useState({
    video: "",
    file: "",
    poster: "",
  });
  useEffect(() => {
    contentServices
      .getHomeContent(1, 8)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setData({
            video: result?.data.video,
            file: result?.data.file,
            poster: result?.data.poster,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <contClientContext.Provider value={{ data, setData }}>
      {props.children}
    </contClientContext.Provider>
  );
};
export default HomeProvider;
