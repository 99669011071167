import React, { useEffect } from "react";
import { useContFile } from "../../Contexts/contentsCtxFile";
import { contentServices } from "../../Services/contentServices";
import Card from "../card/card-file";
import Pagination from "../../Utils/pagination";
import Selecting from "../../Utils/selecting";

export default function FileList() {
  const { contFile, setContFile } = useContFile();
  function onPageChange(page) {
    contentServices
      .getAllContentClient(page, 12, "poster")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContFile({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }
  function contentSelect(select) {
    setContFile(select);
  }

  function handleBtnReset(type) {
    contentServices
      .getAllContentClient(1, 12, type)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContFile({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }
  return (
    <div className="container mx-auto">
      <div className="mx-5 my-10">
        <Selecting
          type="file"
          contentSelect={contentSelect}
          handleBtnReset={handleBtnReset}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
          {/* {contFile?.data.map((e, i) => {
            return <Card data={e} key={i} />;
          })} */}
          {contFile &&
            contFile?.data.map((e, i) => {
              return e.shared === "public" ? <Card data={e} key={i} /> : "";
            })}
        </div>
        <Pagination
          currentPage={contFile?.pagination.currentPage}
          totalPages={Math.ceil(contFile?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}
