import { rgb } from "d3";
import React from "react";
import "../../style/wave.css";

export default function Wave() {
  return (
    <>
      <div className="wave wave1"></div>
      <div className="wave wave2"></div>
      <div className="wave wave3"></div>
      <div className="wave wave4"></div>
    </>
  );
}
