import React from "react";
import { HiOutlineDownload } from "react-icons/hi";

export default function table({
  tags,
  setModalIsOpenEdit,
  setSelected,
  handleDltSubmit,
}) {
  function handleBtnEdit(tags) {
    setSelected(tags);
    setModalIsOpenEdit(true);
  }
  function handleBtnDlt(id) {
    handleDltSubmit(id);
  }

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              №
            </th>
            <th scope="col" className="px-6 py-3">
              Нэр
            </th>
            <th scope="col" className="px-6 py-3">
              Засах
            </th>
            <th scope="col" className="px-6 py-3">
              Устгах
            </th>
          </tr>
        </thead>
        <tbody>
          {tags?.map((e, i) => {
            return (
              <tr
                key={i}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-6 py-4">{i + 1}</td>
                <td className="px-6 py-4">{e.name}</td>

                <td className="px-6 py-4">
                  <button onClick={() => handleBtnEdit(e)}>Засах</button>
                </td>
                <td className="px-6 py-4">
                  <button onClick={() => handleBtnDlt(e.id)}>Устгах</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
