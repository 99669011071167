import React from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  const range = 5;
  const pages = [];
  for (
    let i = Math.max(1, currentPage - range);
    i <= Math.min(totalPages, currentPage + range);
    i++
  ) {
    pages.push(i);
  }

  return (
    <div className="flex justify-end mt-8 font-medium">
      <ul className="flex space-x-2 ">
        {currentPage > 1 && (
          <li className="flex justify-center items-center">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              className="py-1"
            >
              <IoCaretBack />
            </button>
          </li>
        )}
        {pages.map((page) => (
          <li key={page}>
            <button
              onClick={() => onPageChange(page)}
              className={`px-3 py-1 text-[13px]  rounded ${
                currentPage === page ? "bg-[#0a5491] text-white" : "bg-gray-300"
              }`}
            >
              {page}
            </button>
          </li>
        ))}
        {currentPage < totalPages && (
          <li className="flex justify-center items-center">
            <button
              onClick={() => onPageChange(currentPage + 1)}
              className="py-1"
            >
              <IoCaretForward />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
