import React, { createContext, useContext, useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";

const initialVal = {
  contFile: null,
  setContFile: () => {},
};

export const contClientContext = createContext(initialVal);

export function useContFile() {
  return useContext(contClientContext);
}

export const ContFileProvider = (props) => {
  const [contFile, setContFile] = useState();
  useEffect(() => {
    contentServices
      .getAllContentClient(1, 12, "file")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContFile({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <contClientContext.Provider value={{ contFile, setContFile }}>
      {props.children}
    </contClientContext.Provider>
  );
};
export default ContFileProvider;
