import React, { useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";
import { tagsServices } from "../Services/tagsServices";
import { CiUndo } from "react-icons/ci";
import { Select } from "antd";

export default function Selecting({ type, contentSelect, handleBtnReset }) {
  const [options, setOptions] = useState();
  const handleChange = (value) => {
    contentServices
      .getContentSelect(value.value, type, 1, 12)
      .then((res) => res.json())
      .then((result) => contentSelect(result));
  };

  useEffect(() => {
    tagsServices
      .getTagsAllSearch()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setOptions(result.data);
        }
      })
      .finally(() => {});
  }, []);

  function handleBtnReseting(value) {
    handleBtnReset(value);
  }

  return (
    <div className="flex items-center justify-center">
      <Select
        labelInValue
        placeholder="Төрөлөө сонгоно уу ?"
        className="w-full"
        onChange={handleChange}
        options={
          options &&
          options?.map((e, i) => {
            return {
              value: e.name,
              label: e.name,
            };
          })
        }
      />
      <button
        onClick={() => handleBtnReseting(type)}
        className="ml-5 p-1 border rounded border-[gray]"
      >
        <CiUndo size={18} />
      </button>
    </div>
  );
}
