const getContentCreate = async (
  token,
  file,
  type,
  title,
  tags,
  shared,
  perishDate,
  options,
  createdBy
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var formdata = new FormData();
  formdata.append("file", file);
  formdata.append("type", type);
  formdata.append("title", title);
  formdata.append("tags", tags);
  formdata.append("shared", shared);
  formdata.append("perishDate", perishDate);
  formdata.append("sharedEmail", JSON.stringify(options));
  formdata.append("createdBy", createdBy);

  // return await fetch(
  //   `${process.env.REACT_APP_ADMIN_URL}/content`,
  //   requestOptions
  // );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content`,
    requestOptions
  );
};
const getContentEdit = async (id, token, title, tags, shared, createdBy) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    title: title,
    tags: tags,
    shared: shared,
    createdBy: createdBy,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content`,
    requestOptions
  );
};
const getContentDelete = async (token, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content?id=${id}`,
    requestOptions
  );
};
const getContentAll = async (token, page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content?page=${page}&limit=${limit}`,
    requestOptions
  );
};

// Client
const getHomeContent = async (page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/home?page=${page}&limit=${limit}`,
    requestOptions
  );
};
const getAllContentClient = async (page, limit, type) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/client?page=${page}&limit=${limit}&type=${type}`,
    requestOptions
  );
};
const getDownLoadVideo = async (firstName) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/video?fileName=${firstName}`
  );
};
const getDownLoadPoster = async (firstName) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/poster?fileName=${firstName}`
  );
};
const getDownLoadFile = async (firstName) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/file?fileName=${firstName}`
  );
};
const getContentSelect = async (value, type, page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    value: value,
    type: type,
    page: page,
    limit: limit,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/content/select`,
    requestOptions
  );
};

export const contentServices = {
  getContentCreate,
  getContentEdit,
  getContentDelete,
  getContentAll,
  getHomeContent,
  getAllContentClient,
  getDownLoadVideo,
  getDownLoadPoster,
  getDownLoadFile,
  getContentSelect,
};
