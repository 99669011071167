import React, { useState } from "react";
import { tagsServices } from "../../Services/tagsServices";
import { useAuth } from "../../Contexts/authCtx";
import { useTags } from "../../Contexts/tagsCtx";
import { useToken } from "../../Contexts/tokenCtx";
import Notification from "../../Utils/notification";
import Pagination from "../../Utils/pagination";
import moment from "moment";
import Table from "./table";
import Modal from "./modal";

export default function Tags() {
  const [selected, setSelected] = useState();
  const [notification, setNotification] = useState(null);

  // user
  const { auth, setAuth } = useAuth();
  const { tags, setTags } = useTags();
  const { token, setToken } = useToken();

  // Modal open close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => setModalIsOpen(false);
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };
  // Modal open close Edit
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
  const closeModalEdit = () => setModalIsOpenEdit(false);

  // handleSubmit register
  function handleSubmit(e) {
    e.preventDefault();
    const { tags } = e.target;
    if (auth && token) {
      tagsServices
        .getTagsCreate(token, tags.value, auth?.id)
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            setModalIsOpen(false);
            tagsServices
              .getTagsAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result?.success === true) {
                  setTags(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
            setModalIsOpen(false);
          }
        });
    }
  }

  // handleEditSubmit user edit
  function handleEditSubmit(e) {
    e.preventDefault();
    const { tags } = e.target;
    if (auth && token) {
      tagsServices
        .getTagsEdit(token, selected?.id, tags.value, auth?.id)
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            setModalIsOpenEdit(false);
            tagsServices
              .getTagsAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result?.success === true) {
                  setTags(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
            setModalIsOpenEdit(false);
          }
        });
    }
  }

  // handleDltSubmit delete
  function handleDltSubmit(id) {
    if (token) {
      tagsServices
        .getTagsDelete(token, id)
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            tagsServices
              .getTagsAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result?.success === true) {
                  setTags(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
          }
        });
    }
  }
  // pagintaion change
  function onPageChange(page) {
    console.log(page);
    if (token) {
      tagsServices
        .getTagsAll(token, page, 8)
        .then((response) => response.json())
        .then((result) => {
          if (result?.success === true) {
            setTags(result);
          }
        })
        .finally(() => {});
    }
  }

  return (
    <div className="container mx-auto ">
      <div className="mx-5 mb-10">
        <button
          onClick={handleOpenModal}
          className="bg-[#0a5491] my-5 rounded-xl px-10 py-2 text-[14px] text-[#fff]"
        >
          Tags нэмэх
        </button>
        <Table
          tags={tags?.data}
          setSelected={setSelected}
          setModalIsOpenEdit={setModalIsOpenEdit}
          handleDltSubmit={handleDltSubmit}
        />
        <Pagination
          currentPage={tags?.pagination.currentPage}
          totalPages={Math.ceil(tags?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
      </div>
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <div className="text-center">
            <form onSubmit={handleSubmit}>
              <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                TAGS нэмэх
              </h2>

              <input
                required
                type="text"
                name="tags"
                placeholder="tags"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Нэмэх
              </button>
            </form>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {modalIsOpenEdit && selected ? (
        <Modal isOpen={modalIsOpenEdit} closeModal={closeModalEdit}>
          <div className="text-center">
            <form onSubmit={handleEditSubmit}>
              <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                TAGS Edit
              </h2>

              <input
                defaultValue={selected?.name}
                required
                type="text"
                name="tags"
                placeholder="tags"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Нэмэх
              </button>
            </form>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </div>
  );
}
