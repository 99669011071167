import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { userServices } from "../../Services/userServices";
import { useToken } from "../../Contexts/tokenCtx";
import { useUsers } from "../../Contexts/usersCtx";
import Notification from "../../Utils/notification";
import Pagination from "../../Utils/pagination";
import Table from "./table";
import Modal from "./modal";

export default function Users() {
  const { users, setUsers } = useUsers();
  const { token, setToken } = useToken();
  const [selected, setSelected] = useState();
  const [notification, setNotification] = useState(null);
  // Modal open close
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => setModalIsOpen(false);
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  // Modal open close Edit
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
  const closeModalEdit = () => setModalIsOpenEdit(false);

  // Password icon
  const [password, setPassword] = useState("");
  const [typeSign, setTypeSign] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (typeSign === "password") {
      setIcon(eye);
      setTypeSign("text");
    } else {
      setIcon(eyeOff);
      setTypeSign("password");
    }
  };

  // handleSubmit register
  function handleSubmit(e) {
    e.preventDefault();
    const { firstName, email, password, role, size } = e.target;
    if (token) {
      userServices
        .getUserRegister(
          token,
          firstName.value,
          email.value,
          password.value,
          role.value,
          `${size.value}GB`
        )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          // console.log(result.error.name === "SequelizeValidationError");
          // if (result.error?.name === "SequelizeValidationError") {
          //   setNotification({
          //     success: false,
          //     msg: "Нууц үгэнд үсэг, тоо орсон байна",
          //   });
          // }
          if (result.success === true) {
            setNotification(result);
            setModalIsOpen(false);
            userServices
              .getUsersAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setUsers(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
            setModalIsOpen(false);
          }
        });
    }
  }
  // handleEditSubmit user edit
  function handleEditSubmit(e) {
    e.preventDefault();
    const { firstName, action, email, role, size } = e.target;
    if (token && selected) {
      userServices
        .getUserEdit(
          selected.id,
          token,
          firstName.value,
          action.value,
          email.value,
          role.value,
          size.value
        )
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            setModalIsOpenEdit(false);
            userServices
              .getUsersAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setUsers(result);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
            setModalIsOpenEdit(false);
          }
        });
    }
  }
  // handleDltSubmit delete
  function handleDltSubmit(id) {
    if (token) {
      userServices
        .getUserDelete(id, token)
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            userServices
              .getUsersAll(token, 1, 8)
              .then((response) => response.json())
              .then((result) => {
                if (result.success === true) {
                  setUsers(result);
                }
              })
              .finally(() => {});
          }
        });
    }
  }
  // pagintaion change
  function onPageChange(page) {
    if (token) {
      userServices
        .getUsersAll(token, page, 8)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setUsers(result);
          }
        })
        .finally(() => {});
    }
  }

  return (
    <div className="container mx-auto">
      <div className="mx-5 mb-10">
        <button
          onClick={handleOpenModal}
          className="bg-[#0a5491] my-5 rounded-xl px-10 py-2 text-[14px] text-[#fff]"
        >
          Хэрэглэгч нэмэх
        </button>
        <Table
          users={users?.data}
          setSelected={setSelected}
          setModalIsOpenEdit={setModalIsOpenEdit}
          handleDltSubmit={handleDltSubmit}
        />
        <Pagination
          currentPage={users?.pagination.currentPage}
          totalPages={Math.ceil(users?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
      </div>
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <div className="text-center">
            <form onSubmit={handleSubmit}>
              <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                БҮРТГҮҮЛЭХ
              </h2>

              {/* <input
                required
                type="text"
                name="action"
                placeholder="action"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              /> */}
              <input
                required
                type="text"
                name="firstName"
                placeholder="Нэр"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                required
                type="email"
                name="email"
                placeholder="Имэйл хаяг"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <div class="flex justify-between items-center mb-2.5 ">
                <input
                  required
                  type={typeSign}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Нууц үг"
                  className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                />
                <span
                  class="flex justify-around items-center"
                  onClick={handleToggle}
                >
                  <Icon class="absolute mr-10" icon={icon} />
                </span>
              </div>

              {/* <input
                required
                type="text"
                name="role"
                placeholder="Үүрэг"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              /> */}
              <select
                required
                name="role"
                placeholder="action"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              >
                <option value="admin" selected>
                  admin
                </option>
                <option value="user">user</option>
              </select>
              <div className="flex items-center justify-center mb-2.5 ">
                <input
                  required
                  type="text"
                  name="size"
                  placeholder="Хэмжээ"
                  className="w-full rounded-full border border-transparent bg-[#f5f7f6] mr-2 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                />
                <p className="font-medium">GB</p>
              </div>

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Бүртгүүлэх
              </button>
            </form>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {modalIsOpenEdit && selected ? (
        <Modal isOpen={modalIsOpenEdit} closeModal={closeModalEdit}>
          <div className="text-center">
            <form onSubmit={handleEditSubmit}>
              <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                User edit
              </h2>
              <input
                defaultValue={selected?.action}
                required
                type="text"
                name="action"
                placeholder="action"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                defaultValue={selected?.firstName}
                required
                type="text"
                name="firstName"
                placeholder="Нэр"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                defaultValue={selected?.email}
                required
                type="email"
                name="email"
                placeholder="Имэйл хаяг"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              {/* <input
                defaultValue={selected?.role}
                required
                type="text"
                name="role"
                placeholder="Үүрэг"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              /> */}
              <select
                defaultValue={selected?.role}
                required
                name="role"
                placeholder="action"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              >
                <option value="admin">admin</option>
                <option value="user">user</option>
              </select>
              <input
                defaultValue={selected?.size}
                required
                type="text"
                name="size"
                placeholder="Хэмжээ"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
              >
                Засварлах
              </button>
            </form>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </div>
  );
}
