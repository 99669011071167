import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";

const initialVal = {
  token: null,
  setToken: () => {},
};

export const tokenContext = createContext(initialVal);

export function useToken() {
  return useContext(tokenContext);
}
export const TokenProvider = (props) => {
  const [token, setToken] = useState();
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("token")) {
      setToken(cookies.get("token"));
    }
  }, []);

  useEffect(() => {
    const cookies = new Cookies();
    if (token) {
      cookies.set("token", token);
    } else {
      cookies.remove("token");
    }
  }, [token]);

  return (
    <tokenContext.Provider value={{ token, setToken }}>
      {props.children}
    </tokenContext.Provider>
  );
};
export default TokenProvider;
