import React, { createContext, useContext, useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";

const initialVal = {
  contPoster: null,
  setContPoster: () => {},
};

export const contClientContext = createContext(initialVal);

export function useContPoster() {
  return useContext(contClientContext);
}

export const ContPosterProvider = (props) => {
  const [contPoster, setContPoster] = useState();
  useEffect(() => {
    contentServices
      .getAllContentClient(1, 12, "poster")
      .then((response) => response.json())
      .then((result) => {
        setContPoster({
          data: result?.data,
          pagination: result?.pagination,
        });
      })
      .finally(() => {});
  }, []);

  return (
    <contClientContext.Provider value={{ contPoster, setContPoster }}>
      {props.children}
    </contClientContext.Provider>
  );
};
export default ContPosterProvider;
