import React, { useState, useEffect } from "react";
import { HiOutlineUser, HiMiniMagnifyingGlass } from "react-icons/hi2";
import { searchServices } from "../../Services/searchServices";
import { tagsServices } from "../../Services/tagsServices";
import { useNavigate, createSearchParams } from "react-router-dom";

import "../../style/input.css";

export default function Search() {
  const [tags, setTags] = useState();
  const navigate = useNavigate();
  function handleSubmit(e) {
    e.preventDefault();
    navigate({
      pathname: "/search",
      search: `?${createSearchParams({
        values: e.target.search.value,
        page: 1,
      })}`,
    });
  }

  useEffect(() => {
    tagsServices
      .getTagsAllSearch(1, 10)
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          setTags(result?.data);
        }
      });
  }, []);
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="w-[30vw] flex  rounded-md border border-transparent bg-[#d4d8e2] text-left text-[13px] text-secondary-500 outline-none hover:border-neutral-300 focus:border-primary-500"
      >
        <input
          placeholder="хайх"
          name="search"
          className="w-full py-2 pl-3 rounded-md border border-transparent bg-[#d4d8e2] outline-none border-none "
          required
          // list="mylist"
          type="search"
        />
        {/* <datalist id="mylist">
          {tags?.map((e) => {
            return <option value={`${e.name}`}>{e.name}</option>;
          })}
        </datalist> */}
        <button type="submit">
          <HiMiniMagnifyingGlass
            size={18}
            className="mr-3 text-[gray] hover:text-[#000] focus:text-[#000]"
          />
        </button>
      </form>
    </div>
  );
}
