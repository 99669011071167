const getUsersAll = async (token, page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users?page=${page}&limit=${limit}`,
    requestOptions
  );
};

const getUserRegister = async (
  token,
  firstName,
  email,
  password,
  role,
  size
) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    firstName: firstName,
    email: email,
    password: password,
    role: role,
    size: size,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users/register`,
    requestOptions
  );
};

const getUserEdit = async (id, token, firstName, action, email, role, size) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    firstName: firstName,
    action: action,
    email: email,
    role: role,
    size: size,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users`,
    requestOptions
  );
};

const getUserDelete = async (id, token) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users?id=${id}`,
    requestOptions
  );
};

const getUserLogin = async (email, password) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    email: email,
    password: password,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users/login`,
    requestOptions
  );
};

const getUserAuth = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users/auth`,
    requestOptions
  );
};

const getUserForgotPass = async (email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    email: email,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users/forgotPass`,
    requestOptions
  );
};

const getUserResetPass = async (token, password) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    resetToken: token,
    password: password,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/users/resetPass`,
    requestOptions
  );
};
export const userServices = {
  getUsersAll,
  getUserRegister,
  getUserEdit,
  getUserDelete,
  getUserLogin,
  getUserAuth,
  getUserForgotPass,
  getUserResetPass,
};
