import React, { useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
import moment from "moment";

export default function Table({ contents, handleOpenModal }) {
  return (
    <div className="relative overflow-x-auto mb-10">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              №
            </th>
            <th scope="col" className="px-6 py-3">
              Төрөл
            </th>
            <th scope="col" className="px-6 py-3">
              Гарчиг
            </th>
            <th scope="col" className="px-6 py-3">
              #TAG
            </th>
            <th scope="col" className="px-6 py-3">
              Хуваалцсан
            </th>
            <th scope="col" className="px-6 py-3">
              Хэмжээ
            </th>
            <th scope="col" className="px-6 py-3">
              Эзэн
            </th>
            <th scope="col" className="px-6 py-3">
              Татагдсан
            </th>
            <th scope="col" className="px-6 py-3">
              Огноо
            </th>
          </tr>
        </thead>
        <tbody>
          {contents?.map((e, i) => {
            return (
              <tr
                key={i}
                onClick={() => handleOpenModal(e)}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-3 py-3">{i + 1}</td>
                <td className="px-3 py-3 uppercase text-center">{e.type}</td>
                <td className="px-3 py-3">{e.title}</td>
                <td className="px-3 py-3">{e.tags}</td>
                <td className="px-3 py-3">{e.shared}</td>
                <td className="px-3 py-3">{e.fileSize}</td>
                <td className="px-3 py-3">{e.creator.email}</td>
                <td className="px-3 py-3 text-center">{e.download}</td>
                <td className="px-3 py-3">{moment(e.createdAt).format("L")}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
