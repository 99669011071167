import React, { createContext, useContext, useEffect, useState } from "react";
import { tagsServices } from "../Services/tagsServices";
import { useToken } from "./tokenCtx";
import moment from "moment";

const initialVal = {
  tags: null,
  setTags: () => {},
};

export const TagsContext = createContext(initialVal);

export function useTags() {
  return useContext(TagsContext);
}

export const TagsProvider = (props) => {
  const [tags, setTags] = useState();
  const { token, setToken } = useToken();
  useEffect(() => {
    if (token) {
      tagsServices
        .getTagsAll(token, 1, 8)
        .then((response) => response.json())
        .then((result) => {
          if (result?.success === true) {
            setTags(result);
          }
        })
        .finally(() => {});
    }
  }, [token]);

  return (
    <TagsContext.Provider value={{ tags, setTags }}>
      {props.children}
    </TagsContext.Provider>
  );
};
export default TagsProvider;
