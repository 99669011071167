import React, { useState } from "react";
import { useAuth } from "../../Contexts/authCtx";
import { useToken } from "../../Contexts/tokenCtx";
import { useUsers } from "../../Contexts/usersCtx";
import { userServices } from "../../Services/userServices";
import Notification from "../../Utils/notification";
export default function About() {
  const { auth, setAuth } = useAuth();
  const { token, setToken } = useToken();
  const { users, setUsers } = useUsers();
  const [notification, setNotification] = useState(null);

  function handleEditSubmit(e) {
    e.preventDefault();
    const { firstName, action, email, role, size } = e.target;
    if (token && auth) {
      userServices
        .getUserEdit(
          auth.id,
          token,
          firstName.value,
          action.value,
          email.value,
          role.value,
          size.value
        )
        .then((res) => res.json())
        .then((result) => {
          if (result.success === true) {
            setNotification(result);
            userServices
              .getUserAuth(token)
              .then((response) => response.json())
              .then((result) => {
                console.log(result);
                if (result.success === true) {
                  setAuth(result.user);
                }
              })
              .finally(() => {});
          } else {
            setNotification(result);
          }
        });
    }
  }
  return (
    <div className="container mx-auto">
      <div className="m-5">
        <form onSubmit={handleEditSubmit}>
          <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
            Хэрэглэгчийн мэдээлэл
          </h2>
          <div className="flex mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Файл :
            </label>
            <input
              defaultValue={auth?.action}
              required
              type="text"
              name="action"
              placeholder="action"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Нэр :
            </label>
            <input
              defaultValue={auth?.firstName}
              required
              type="text"
              name="firstName"
              placeholder="Нэр"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Имэйл хаяг:
            </label>
            <input
              defaultValue={auth?.email}
              required
              type="email"
              name="email"
              placeholder="Имэйл хаяг"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Зэрэглэл :
            </label>
            <input
              defaultValue={auth?.role}
              required
              type="text"
              name="role"
              placeholder="Үүрэг"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2] py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Ашиглах дата :
            </label>
            <input
              defaultValue={auth?.size}
              required
              type="text"
              name="size"
              placeholder="Хэмжээ"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <button
            type="submit"
            className="w-full uppercase rounded-xl border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
          >
            Засварлах
          </button>
        </form>
      </div>
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </div>
  );
}
