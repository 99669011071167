import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { FaX } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function drawer({ isOpen, toggleDrawer, auth }) {
  function handleBtnClose() {
    toggleDrawer(false);
  }
  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="right"
      className="flex justify-between items-start"
    >
      <div className="p-5 flex flex-col uppercase ">
        <Link to="/video" className="mr-10 text-[14px]">
          Видео
        </Link>
        <Link to="/poster" className="mr-10 text-[14px]">
          Постер
        </Link>
        <Link to="/file" className="mr-10 text-[14px]">
          Файл
        </Link>
        {auth !== undefined && auth?.role === "admin" ? (
          <>
            <Link to="/users" className="text-[14px]">
              Хэрэглэгч
            </Link>

            <Link to="/tags" className="text-[14px]">
              Tags
            </Link>
            <Link to="/contents" className="mr-10 text-[14px]">
              contents
            </Link>
            <Link to="/rates" className="mr-10 text-[14px]">
              rates
            </Link>
          </>
        ) : auth?.role === "user" ? (
          <Link to="/contents" className="mr-10 text-[14px]">
            contents
          </Link>
        ) : (
          ""
        )}
      </div>
      <button onClick={handleBtnClose} className="m-2">
        <FaX />
      </button>
    </Drawer>
  );
}
