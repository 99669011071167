import React from "react";
import { Chart1, Chart2, Video, Poster, File, Wave } from "../Components";
import { useData } from "../Contexts/homeCtx.js";
import "../style/App.css";

export default function Home() {
  const { data, setData } = useData();
  return (
    <div>
      <div className="h-[65vh] flex flex-col items-end justify-center coverImg w-full">
        <div className="flex items-end w-full relative h-[65vh]">
          {/* <Chart2 />
          <Chart1 /> */}
          <Wave />
        </div>
      </div>
      {data && (
        <div>
          <Video data={data?.video} />
          <Poster data={data?.poster} />
          <File data={data?.file} />
        </div>
      )}
    </div>
  );
}
