import React, { createContext, useContext, useEffect, useState } from "react";
import { useToken } from "./tokenCtx";
import { userServices } from "../Services/userServices";

const initialVal = {
  users: null,
  setUsers: () => {},
};

export const userContext = createContext(initialVal);

export function useUsers() {
  return useContext(userContext);
}
export const UserProvider = (props) => {
  const [users, setUsers] = useState();
  const { token, setToken } = useToken();
  useEffect(() => {
    if (token) {
      userServices
        .getUsersAll(token, 1, 8)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setUsers(result);
          }
        })
        .finally(() => {});
    }
  }, [token]);

  return (
    <userContext.Provider value={{ users, setUsers }}>
      {props.children}
    </userContext.Provider>
  );
};
export default UserProvider;
