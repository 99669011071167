const getRatesCreate = async (title, desc, rate) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    title: title,
    desc: desc,
    rate: rate,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/rates`,
    requestOptions
  );
};

const getRatesAll = async (page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/rates?page=${page}&limit=${limit}`,
    requestOptions
  );
};

export const ratesServices = {
  getRatesCreate,
  getRatesAll,
};
