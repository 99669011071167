import logo from "./logo.svg";
import Home from "./Home/Home";
import Layout from "./Layout/Layout";
import { Route, Routes } from "react-router-dom";
import {
  Users,
  Tags,
  About,
  Content,
  VideoList,
  PosterList,
  FileList,
  Search,
} from "./Components";
import { Contents, Rates } from "./Dashboards/index";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Home />} />
          <Route path="/video" element={<VideoList />} />
          <Route path="/poster" element={<PosterList />} />
          <Route path="/file" element={<FileList />} />
          <Route path="/users" element={<Users />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/about" element={<About />} />
          <Route path="/uploads" element={<Content />} />
          <Route path="/contents" element={<Contents />} />
          <Route path="/rates" element={<Rates />} />
          <Route path="/search?" element={<Search />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
