import React, { useState } from "react";
import { useTags } from "../../Contexts/tagsCtx";
import { useToken } from "../../Contexts/tokenCtx";
import { useAuth } from "../../Contexts/authCtx";
import { contentServices } from "../../Services/contentServices";
import { useContents } from "../../Contexts/contentsCtx";
import { useNavigate } from "react-router-dom";
import Processing from "../../Utils/progressing";
import Notification from "../../Utils/notification";
import Progress from "./progress";
import axios from "axios";
import { Select } from "antd";
import "../../style/input.css";

export default function Content() {
  const { tags, setTags } = useTags();
  const { token, setToken } = useToken();
  const { auth, setAuth } = useAuth();

  const { contents, setContents } = useContents();
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  const [inputType, setInputType] = useState("text");
  const [select, setSelect] = useState();
  const [options, setOptions] = useState([]);
  const [processing, setProcessing] = useState();

  const handleFocus = () => {
    setInputType("date");
  };

  const handleBlur = () => {
    setInputType("text");
  };
  function convertToBytes(sizeString) {
    const [size, unit] = sizeString.split(" ");
    let sizeInBytes;
    if (unit === "KB") {
      sizeInBytes = parseFloat(size) * 1024;
    } else if (unit === "MB") {
      sizeInBytes = parseFloat(size) * 1024 * 1024;
    } else if (unit === "GB") {
      sizeInBytes = parseFloat(size) * 1024 * 1024 * 1024;
    }
    return sizeInBytes;
  }

  async function handleContentCreate(e) {
    e.preventDefault();
    const { type, file, title, tags, shared, perishDate, email } = e.target;
    if (token && auth) {
      const sizeInBytes = convertToBytes(auth?.size);
      const useSizeInBytes = convertToBytes(auth?.useSize);
      if (sizeInBytes >= useSizeInBytes) {
        setNotification({
          success: false,
          msg: "Лимит хэтэрсэн байна",
        });
        return;
      }
      const data = new FormData();
      data.append("file", file.files[0]);
      data.append("type", type.value);
      data.append("title", title.value);
      data.append("tags", tags.value);
      data.append("shared", shared.value);
      data.append("perishDate", perishDate.value);
      data.append("sharedEmail", JSON.stringify(options));
      data.append("createdBy", auth?.id);
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_ADMIN_URL}/content`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            processCallFunction(progress);
          },
        };
        axios
          .request(config)
          .then((response) => {
            if (response.data.success === true) {
              setNotification(response.data);
              contentServices
                .getContentAll(token, 1, 8)
                .then((response) => response.json())
                .then((result) => {
                  if (result?.success === true) {
                    setContents(result);
                    navigate("/contents");
                  }
                })
                .finally(() => {});
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
    // if (token && auth) {
    //   const sizeInBytes = convertToBytes(auth?.size);
    //   const useSizeInBytes = convertToBytes(auth?.useSize);

    //   if (sizeInBytes >= useSizeInBytes) {
    //     setNotification({
    //       success: false,
    //       msg: "Лимит хэтэрсэн байна",
    //     });
    //     return;
    //   }

    //   const formData = new FormData();
    //   formData.append("file", file.files[0]);
    //   formData.append("type", type.value);
    //   formData.append("title", title.value);
    //   formData.append("tags", tags.value);
    //   formData.append("shared", shared.value);
    //   formData.append("perishDate", perishDate.value);
    //   formData.append("options", JSON.stringify(options));
    //   formData.append("userId", auth?.id);

    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_ADMIN_URL}/content`,
    //       formData,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token} `,
    //         },
    //         onUploadProgress: (progressEvent) => {
    //           const progress = Math.round(
    //             (progressEvent.loaded / progressEvent.total) * 100
    //           );
    //           console.log(`Upload Progress: ${progress}%`);
    //           // Update your progress UI here if needed
    //         },
    //       }
    //     );

    //     const result = response.data;
    //     console.log(result);
    //     if (result.success === true) {
    //       setNotification(result);
    //       const contentResponse = await contentServices.getContentAll(
    //         token,
    //         1,
    //         8
    //       );
    //       const contentResult = await contentResponse.json();
    //       if (contentResult?.success === true) {
    //         setContents(contentResult);
    //         navigate("/contents");
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //   }
    // }
  }

  function handleBtnSharedType(e) {
    setSelect(e.target.value);
  }

  const handleChange = (value) => {
    setOptions(value);
  };

  function processCallFunction(value) {
    setProcessing(value);
  }

  return (
    <div className="container mx-auto">
      <div className="m-5">
        <form onSubmit={handleContentCreate}>
          <h2 className="pb-4 text-h5 font-bold uppercase sm:text-h2 text-center">
            upload file
          </h2>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Төрөл :
            </label>
            <select
              required
              name="type"
              placeholder="type"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            >
              <option selected value="video">
                video
              </option>
              <option value="poster">poster</option>
              <option value="file">file</option>
            </select>
          </div>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Файл :
            </label>
            <input
              required
              type="file"
              name="file"
              placeholder="file"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
            {/* <MdOutlineNoteAdd
              limit={18}
              className="absolute ml-[128px] md:ml-[150px] lg:ml-[160px] xl:ml-[163px]"
            /> */}
          </div>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Гарчиг :
            </label>
            <input
              required
              type="text"
              name="title"
              placeholder="Гарчиг"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              #Tag :
            </label>
            <select
              required
              name="tags"
              placeholder="tags"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            >
              {tags?.data.map((tag, i) => {
                return (
                  <option key={i} selected value={tag.name}>
                    {tag.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Хуваалцах төрөл :
            </label>
            <select
              required
              name="shared"
              onChange={handleBtnSharedType}
              placeholder="shared"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            >
              <option selected value="public">
                Бүгд нь
              </option>
              <option value="only me">Өөртөө</option>
              <option value="other">Бусдад</option>
            </select>
          </div>
          {select === "other" ? (
            <div className="flex  mb-2.5 flex items-center">
              <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
                Емайл оруулах :
              </label>
              <Select
                mode="tags"
                className="w-full ml-5"
                placeholder="Майл хаягаа оруулана уу"
                onChange={handleChange}
              />
            </div>
          ) : (
            ""
          )}
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Устгах өдөр :
            </label>
            <input
              defaultValue={inputType === "date" ? formattedDate : undefined}
              placeholder="Сонгоогүй бол устахгүй"
              type={inputType}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="perishDate"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />
          </div>
          <div className="flex  mb-2.5 flex items-center">
            <label className="w-[150px] mb-2 md:mb-0 text-[14px] text-left md:text-right">
              Хуулах лимит /GB/:
            </label>
            {auth && (
              <Progress limitSize={auth?.size} currentSize={auth?.useSize} />
            )}
            {/* <input
              required
              type="text"
              name="limit"
              placeholder="Хэмжээ"
              className="ml-5 w-full rounded-md border border-transparent bg-[#d4d8e2]  py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            /> */}
          </div>
          <button
            type="submit"
            className="w-full uppercase rounded-xl border border-transparent bg-[#0a5491] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
          >
            Хуулах
          </button>
        </form>
      </div>
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
      {processing ? <Processing value={processing} /> : ""}
    </div>
  );
}
