import React, { useState } from "react";
import { useRates } from "../../Contexts/ratesCtx";
import { ratesServices } from "../../Services/ratesServices";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Utils/pagination";
import Notification from "../../Utils/notification";
import Table from "./table";

export default function Content() {
  const { rates, setRates } = useRates();
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  // MOdal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selected, setSelected] = useState();

  function handleOpenModal(e) {
    setSelected(e);
    setModalIsOpen(true);
  }

  // pagination change
  function onPageChange(page) {
    ratesServices
      .getRatesAll(page, 8)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result?.success === true) {
          setRates(result);
        }
      })
      .finally(() => {});
  }

  return (
    <div className="container mx-auto">
      <div className="mx-5 my-10">
        <Table rates={rates?.data} handleOpenModal={handleOpenModal} />
        <Pagination
          currentPage={rates?.pagination.currentPage}
          totalPages={Math.ceil(rates?.pagination.totalPages)}
          onPageChange={onPageChange}
        />
        {notification ? (
          <Notification
            notification={notification}
            setNotification={setNotification}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
