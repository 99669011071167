import React, { useState } from "react";
import { HiOutlineDownload, HiArrowsExpand } from "react-icons/hi";
import { contentServices } from "../../Services/contentServices";
import Notification from "../../Utils/notification";

export default function Page({ data }) {
  const [notification, setNotification] = useState(null);
  const [event, setEvent] = useState({
    id: "",
    isHovered: false,
  });

  async function handleBtnDownload(fileName) {
    let response = await contentServices.getDownLoadFile(fileName);
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setNotification({
        success: true,
        msg: "Амжилттай татагдсан",
      });
    } else {
      setNotification({
        success: false,
        msg: `Амжилтгүй ${response.statusText} ${response.status}`,
      });
    }
  }

  return (
    <div
      className="relative shadow-md border p-3 rounded w-full"
      onMouseEnter={() =>
        setEvent((prevEvent) => ({ ...prevEvent, isHovered: true }))
      }
      onMouseLeave={() =>
        setEvent((prevEvent) => ({ ...prevEvent, isHovered: false }))
      }
    >
      {event?.isHovered === true ? (
        <button
          onClick={() => handleBtnDownload(data?.fileName)}
          className={`z-20 absolute left-0 top-0 w-full h-full bg-[#000] bg-opacity-20 flex items-center justify-center ${
            event.isHovered ? "" : "hidden"
          }`}
        >
          <HiOutlineDownload size={30} className="text-[#fff]" />
        </button>
      ) : (
        ""
      )}

      <div className="flex items-center">
        <img src={`./logo/${data?.fileName.slice(14)}.png`} />
        <div className="ml-3">
          <p className="font-bold">{data?.title}</p>
          <p>{data?.fileSize}</p>
        </div>
      </div>
    </div>
  );
}
