import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaRegUser, FaAlignJustify } from "react-icons/fa6";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { userServices } from "../../Services/userServices";
import { useToken } from "../../Contexts/tokenCtx";
import { useAuth } from "../../Contexts/authCtx";
import DropdownMenu from "./dropDown";
import DropdownUser from "./dropDownUser";
import Search from "../search/search";
import Drawer from "./drawer";
import Modal from "./modal";
import Notification from "../../Utils/notification";

export default function Header() {
  const location = useLocation();
  const [notification, setNotification] = useState(null);
  // token && user
  const { token, setToken } = useToken();
  const { auth, setAuth } = useAuth();
  // Draver
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  // Modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsForgot, setModalIsForgot] = useState(false);
  const closeModal = () => setModalIsOpen(false);
  const closeModalForgot = () => setModalIsForgot(false);
  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  // Password icon
  const [password, setPassword] = useState("");
  const [typeSign, setTypeSign] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (typeSign === "password") {
      setIcon(eye);
      setTypeSign("text");
    } else {
      setIcon(eyeOff);
      setTypeSign("password");
    }
  };

  // Login
  function handleSubmit(e) {
    e.preventDefault();
    const { email, password } = e.target;
    userServices
      .getUserLogin(email.value, password.value)
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          setNotification(result);
          setToken(result.token);
          setModalIsOpen(false);
        } else {
          setNotification(result);
          setModalIsOpen(false);
        }
      });
  }
  // Forgot pass
  function handleSubmitForgot(e) {
    e.preventDefault();
    const { email } = e.target;
    userServices
      .getUserForgotPass(email.value)
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
      });
  }

  function handleForgetPass() {
    setModalIsForgot(true);
    setModalIsOpen(false);
  }

  return (
    <div className="w-full shadow-md">
      <div className="container mx-auto py-3 flex justify-between items-center ">
        <div className="flex items-center">
          <div className="mr-0 lg:mr-10  flex items-center justify-start">
            <Link to="/">
              <img src="../logo-1.png" className="h-[2.5em] ml-3 lg:ml-0" />
            </Link>
          </div>
          <div className="hidden lg:block flex">
            <Link to="/video" className="mr-10 text-[14px]">
              Видео
            </Link>
            <Link to="/poster" className="mr-10 text-[14px]">
              Постер
            </Link>
            <Link to="/file" className="mr-10 text-[14px]">
              Файл
            </Link>
            {auth ? <DropdownMenu auth={auth} /> : ""}
          </div>
        </div>
        <Search />
        <div className="flex">
          {auth ? (
            <DropdownUser user={auth} />
          ) : (
            <>
              {location?.pathname !== "/" &&
              location?.pathname !== "/video" &&
              location?.pathname !== "/file" &&
              location?.pathname !== "/poster" ? (
                <>
                  <button
                    onClick={handleOpenModal}
                    className="text-[14px] text-[#fff] hidden lg:block bg-[#0a5491] py-2 p-4 rounded-xl"
                  >
                    Нэвтрэх
                  </button>
                  <button
                    onClick={handleOpenModal}
                    className="text-[14px] block lg:hidden"
                  >
                    <FaRegUser size={18} />
                  </button>
                </>
              ) : (
                ""
              )}
            </>
          )}
          <button
            className="text-[14px] block lg:hidden"
            onClick={toggleDrawer}
          >
            <FaAlignJustify size={18} className="ml-2 mr-3" />
          </button>
        </div>
      </div>
      {isOpen ? (
        <Drawer
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
          auth={auth}
          setAuth={setAuth}
        />
      ) : (
        ""
      )}
      {modalIsOpen ? (
        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <form onSubmit={handleSubmit} className="w-[280px] md:w-[300px]">
            <h2 className="my-2 flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
              НЭВТРЭХ
            </h2>
            <input
              required
              type="email"
              placeholder="Имэйл хаяг"
              name="email"
              className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />

            <div class="flex justify-between items-center mb-2.5 ">
              <input
                required
                type={typeSign}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Нууц үг"
                className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <span
                class="flex justify-around items-center"
                onClick={handleToggle}
              >
                <Icon class="absolute mr-10" icon={icon} />
              </span>
            </div>
            <button className="mb-2.5 text-right">
              <button
                onClick={handleForgetPass}
                className="text-[12px] border-b border-[#000]"
              >
                Нууц үг шинэчлэх
              </button>
            </button>
            <button
              type="submit"
              className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
            >
              Нэвтрэх
            </button>
          </form>
        </Modal>
      ) : (
        ""
      )}
      {modalIsForgot ? (
        <Modal isOpen={modalIsForgot} closeModal={closeModalForgot}>
          <form
            onSubmit={handleSubmitForgot}
            className="w-[280px] md:w-[300px]"
          >
            <h2 className="my-2 flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
              НУУЦ ҮГ СЭРГЭЭХ
            </h2>
            <input
              required
              type="email"
              placeholder="Имэйл хаяг"
              name="email"
              className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-sm outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
            />

            {/* <button className="mb-2.5 text-right">
              <button
                onClick={handleForgetPass}
                className="text-[12px] border-b border-[#000]"
              >
                Нууц үг шинэчлэх
              </button>
            </button> */}
            <button
              type="submit"
              className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-sm sm:text-xs"
            >
              СЭРГЭЭХ
            </button>
          </form>
        </Modal>
      ) : (
        ""
      )}
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </div>
  );
}
