import React, { useEffect, useState } from "react";
import Card from "../card/card-poster";
import { useNavigate } from "react-router-dom";
import { contentServices } from "../../Services/contentServices";

export default function Page({ data }) {
  const navigate = useNavigate();
  function handleNavigate() {
    navigate("/poster ");
  }
  return (
    <div id="poster" className="container mx-auto mt-5">
      <div className="mx-5">
        <p className="text-[1em] uppercase font-medium">ПОСТЕР</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
          {data &&
            data?.map((e, i) => {
              return e.shared === "public" ? <Card data={e} key={i} /> : "";
            })}
        </div>
        <button
          onClick={handleNavigate}
          className="text-center w-full text-[12px] mb-5"
        >
          <span className="bg-[#0a5491] rounded-xl px-10 py-2 text-[14px] text-[#fff]">
            Бүгдийг харах
          </span>
        </button>
      </div>
    </div>
  );
}
