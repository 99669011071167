import React, { useEffect, useState } from "react";

export default function Progress({ limitSize, currentSize }) {
  const [size, setSize] = useState({
    limitSizeNum: 0,
    currentSizeNum: 0,
  });

  useEffect(() => {
    const parseSize = (sizeString) => {
      const [, value, unit] = sizeString.match(/^([\d.]+)\s*( B|KB|MB|GB)$/);
      const multiplier = { KB: 1024, MB: 1024 * 1024, GB: 1024 * 1024 * 1024 };
      return parseFloat(value) * multiplier[unit];
    };

    setSize({
      limitSizeNum: parseSize(limitSize),
      currentSizeNum: currentSize ? parseSize(currentSize) : 0,
    });
  }, [limitSize, currentSize]);

  const progress =
    size.limitSizeNum !== 0
      ? (size.currentSizeNum * 100) / size.limitSizeNum
      : 0;

  return (
    <div className="flex flex-col w-full ml-5">
      <progress
        value={progress}
        max={100}
        placeholder="ss"
        className="w-full h-5"
      />
      <p className="text-[14px] text-center">
        {currentSize} / {limitSize}
      </p>
    </div>
  );
}
