import React from "react";

export default function Table({
  users,
  setModalIsOpenEdit,
  setSelected,
  handleDltSubmit,
}) {
  function handleBtnEdit(user) {
    setSelected(user);
    setModalIsOpenEdit(true);
  }

  function handleBtnDlt(id) {
    handleDltSubmit(id);
  }
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              №
            </th>
            <th scope="col" className="px-6 py-3">
              Нэр
            </th>
            <th scope="col" className="px-6 py-3">
              Имэйл
            </th>
            <th scope="col" className="px-6 py-3">
              role
            </th>
            <th scope="col" className="px-6 py-3">
              size
            </th>
            <th scope="col" className="px-6 py-3">
              action
            </th>
            <th scope="col" className="px-6 py-3">
              edit
            </th>
            <th scope="col" className="px-6 py-3">
              delete
            </th>
          </tr>
        </thead>
        <tbody>
          {users?.map((e) => {
            return (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td className="px-6 py-4">{e.id}</td>
                <td className="px-6 py-4">{e.firstName}</td>
                <td className="px-6 py-4">{e.email}</td>
                <td className="px-6 py-4">{e.role}</td>
                <td className="px-6 py-4">{e.size}</td>
                <td className="px-6 py-4">{e.action}</td>
                <td className="px-6 py-4">
                  <button onClick={() => handleBtnEdit(e)}>edit</button>
                </td>
                <td className="px-6 py-4">
                  <button onClick={() => handleBtnDlt(e.id)}>Устгах</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
