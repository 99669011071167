import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { searchServices } from "../../Services/searchServices";
import CardPoster from "../card/card-poster";
import CardVideo from "../card/card-video";
import Pagination from "../../Utils/pagination";

export default function Result() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const values = searchParams.get("values");
  const page = parseInt(searchParams.get("page"));
  const [data, setData] = useState();

  useEffect(() => {
    searchServices
      .getSearchValue(page, 12, values)
      .then((response) => response.json())
      .then((result) => {
        console.log(result?.data.length);
        if (result?.data.length > 0) {
          if (result?.success === true) {
            setData(result);
          }
        } else {
          setData("Илэрц алга");
        }
      })
      .finally(() => {});
  }, [values, page]);

  function onPageChange(page) {
    searchServices
      .getSearchValue(page, 12, values)
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setData(result);
        }
      })
      .finally(() => {});
  }

  return (
    <div className="container mx-auto">
      <div className="mx-5 my-10">
        {data !== "Илэрц алга" ? (
          <>
            {data &&
              data.data?.map((e, i) =>
                e.type === "video" ? (
                  <p className="text-[1em] uppercase font-medium">
                    Интерактив хичээлүүд
                  </p>
                ) : (
                  ""
                )
              )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
              {data &&
                data.data?.map((e, i) =>
                  e.type === "video" ? <CardVideo data={e} key={i} /> : ""
                )}
            </div>
            {data &&
              data.data?.map((e, i) =>
                e.type === "poster" ? (
                  <p className="text-[1em] uppercase font-medium">
                    Интерактив хичээлүүд
                  </p>
                ) : (
                  ""
                )
              )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
              {data &&
                data.data?.map((e, i) =>
                  e.type === "poster" ? <CardPoster data={e} key={i} /> : ""
                )}
            </div>
            {data &&
              data.data?.map((e, i) =>
                e.type === "file" ? (
                  <p className="text-[1em] uppercase font-medium">
                    Интерактив хичээлүүд
                  </p>
                ) : (
                  ""
                )
              )}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-5">
              {data &&
                data.data?.map((e, i) =>
                  e.type === "file" ? <CardPoster data={e} key={i} /> : ""
                )}
            </div>
            <Pagination
              currentPage={data?.pagination.currentPage}
              totalPages={Math.ceil(data?.pagination.totalPages)}
              onPageChange={onPageChange}
            />
          </>
        ) : (
          <div className="h-[60vh]">{data}</div>
        )}
      </div>
    </div>
  );
}
