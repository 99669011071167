import React, { createContext, useContext, useEffect, useState } from "react";
import { contentServices } from "../Services/contentServices";

const initialVal = {
  contVideo: null,
  setContVideo: () => {},
};

export const contClientContext = createContext(initialVal);

export function useContVideo() {
  return useContext(contClientContext);
}

export const ContVideoProvider = (props) => {
  const [contVideo, setContVideo] = useState();
  useEffect(() => {
    contentServices
      .getAllContentClient(1, 12, "video")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setContVideo({
            data: result?.data,
            pagination: result?.pagination,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <contClientContext.Provider value={{ contVideo, setContVideo }}>
      {props.children}
    </contClientContext.Provider>
  );
};
export default ContVideoProvider;
