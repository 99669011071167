import React from "react";
import {
  CiClock2,
  CiMail,
  CiLocationOn,
  CiFacebook,
  CiInstagram,
  CiLinkedin,
} from "react-icons/ci";

export default function page() {
  return (
    <div className="bg-[#0a5491] mb-[-5vh] text-[14px]">
      <div className="container mx-auto  text-[#fff]">
        <div className="p-10 grid grid-cols-1 md:grid-cols-2">
          <div className="grid grid-cols-2 md:grid-cols-2">
            <img src="./part1.png" className="w-[150px] h-[100px]" alt="" />
            <img src="./part2.png" className="w-[150px] h-[100px]" alt="" />
            <img src="./part3.png" className="w-[150px] h-[100px]" alt="" />
            <img src="./part4.png" className="w-[150px] h-[100px]" alt="" />
          </div>

          <div className="text-left md:text-left">
            <p className="text-[1.5em]">Холбоо барих</p>
            <div className="flex items-center mt-3">
              <p className="p-1 border rounded">
                <CiClock2 size={23} />
              </p>
              <div className="ml-3">
                <p>Даваа - Баасан 08:30 - 17:30</p>
                <p>Бямба - Ням Амарна</p>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <p className="p-1 border rounded">
                <CiMail size={23} />
              </p>
              <div className="ml-3">
                <p>info@crc.gov.mn</p>
              </div>
            </div>
            <div className="flex items-center mt-3">
              <p className="p-1 border rounded">
                <CiLocationOn size={23} />
              </p>
              <div className="ml-3">
                <p>
                  Метро бизнес төв А-блок 5 давхар Д.Сүхбаатарын гудамж-13
                  Сүхбаатар дүүрэг, Улаанбаатар 14201-0033
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-5 flex flex-col md:flex-row justify-between  border-t-[1px]">
          <div className="flex items-start mt-3">
            <p className="p-1 border rounded">
              <a target="_blank" href="https://www.facebook.com/crcmongol">
                <CiFacebook size={23} />
              </a>
            </p>
            <p className="p-1 mx-3 border rounded">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCGkQ7CBtsia7fuxOK7_QzBg"
              >
                <CiInstagram size={23} />
              </a>
            </p>
            <p className="p-1 border rounded">
              <a target="_blank" href="https://twitter.com/">
                <CiLinkedin size={23} />
              </a>
            </p>
          </div>
          <p className="py-5 text-[12px] text-left md:text-right">
            Copyright © 1995-2023 Харилцаа холбооны Зохицуулах Хороо
          </p>
        </div>
      </div>
    </div>
  );
}
