const getTagsCreate = async (token, name, createdBy) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    name: name,
    createdBy: createdBy,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/tags`, requestOptions);
};

const getTagsEdit = async (token, id, name, createdBy) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    name: name,
    createdBy: createdBy,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/tags`, requestOptions);
};

const getTagsDelete = async (token, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/tags?id=${id}`,
    requestOptions
  );
};

const getTagsAll = async (token, page, limit) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/tags?page=${page}&limit=${limit}`,
    requestOptions
  );
};

const getTagsAllSearch = async () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/tags/select`,
    requestOptions
  );
};

export const tagsServices = {
  getTagsCreate,
  getTagsEdit,
  getTagsDelete,
  getTagsAll,
  getTagsAllSearch,
};
