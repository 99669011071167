import React, { useState } from "react";
import { HiOutlineDownload, HiArrowsExpand } from "react-icons/hi";
import { CiMaximize1, CiImport } from "react-icons/ci";
import { contentServices } from "../../Services/contentServices";
import Notification from "../../Utils/notification";
import Modal from "../poster/modal";

export default function Page({ data }) {
  const [notification, setNotification] = useState(null);
  const [event, setEvent] = useState({
    id: "",
    isHovered: false,
    isClick: false,
  });
  // Modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => setModalIsOpen(false);

  async function handleBtnDown(fileName) {
    let response = await contentServices.getDownLoadPoster(fileName);
    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      setNotification({
        success: true,
        msg: "Амжилттай татагдсан",
      });
      // Cleanup
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      setNotification({
        success: false,
        msg: `Амжилтгүй ${response.statusText} ${response.status}`,
      });
    }
  }

  function handleBtnZoom() {
    setModalIsOpen(true);
  }

  return (
    <>
      <div
        className="w-full h-[250px] relative bg-cover bg-no-repeat bg-top"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_UPLOADS_URL}/${data?.fileName})`,
        }}
        onMouseEnter={() =>
          setEvent((prevEvent) => ({ ...prevEvent, isHovered: true }))
        }
        onMouseLeave={() =>
          setEvent((prevEvent) => ({ ...prevEvent, isHovered: false }))
        }
        onClick={() =>
          setEvent((prevEvent) => ({ ...prevEvent, isClick: false }))
        }
      >
        {event?.isHovered === true || event?.isClick === true ? (
          <div
            className={`z-20 absolute left-0 top-0 w-full h-full bg-[#000] bg-opacity-20 flex items-center justify-center ${
              event.isHovered ? "" : "hidden"
            }`}
          >
            <button onClick={() => handleBtnZoom(data?.fileName)}>
              <HiOutlineDownload size={30} className="text-[#fff]" />
            </button>
            <button onClick={() => handleBtnDown(data?.fileName)}>
              <HiArrowsExpand size={30} className="text-[#fff]" />
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {modalIsOpen && data ? (
        <Modal isOpen={modalIsOpen} closeModal={closeModal}>
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/${data.fileName}`}
            alt="Full Size"
            className="h-full w-full"
          />
        </Modal>
      ) : (
        ""
      )}
      {notification ? (
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
      ) : (
        ""
      )}
    </>
  );
}
